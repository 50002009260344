a {
  color: black;
}
a:hover {
  color: black;
}

.icons-size {
  transform: scale(0.6);
}
.active {
  background-color: white;
  color: #f47d3a;
  border-radius: 10px;
  /* height: 100px; */
}
.active:hover {
  background-color: white;
  color: #f47d3a;
  border-radius: 10px;
}
.active svg {
  fill: #f47d3a;
}

aside {
  color: black;
  background-color: #feeada;
  overflow: scroll;
  margin: 20px 0;
  padding: 5%;
}
aside::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.menu-list {
  height: 75vh;
  overflow: auto;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
}
.menu-list::-webkit-scrollbar {
  display: none;
}
aside a {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  margin: 3px 0px;
  height:50px;
}
.userData-menu > img {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}
.userData-menu {
  display: flex;
  width: 100%;
  padding: 0 5%;
  align-items: center;
}
aside a i {
  margin-right: 5px;
}

aside p {
  margin: 0;
  padding: 40px 0;
}

body {
  font-family: 'Roboto';
  width: 100%;
  height: 100vh;
  margin: 0;
}
